import React from 'react'
import Footer from './Footer';
import background from "../assets/homebackground.png";
import Blog2 from "../assets/Blog2.png";
import Blog3 from "../assets/Blog3.png";
import Blog4 from "../assets/Blog4.png";
import Blog5 from "../assets/Blog5.png";
import Blo1 from "../assets/Group 2649.png";
import Blo2 from "../assets/Group 26z46.png";
import Blo3 from "../assets/Groupd 2648.png";
import Blo4 from "../assets/Group 2650.png";
import Bl1 from "../assets/Group 2683.png";
import Bl2 from "../assets/Group 2684.png";
import Bl3 from "../assets/Group 2685.png";
import B1 from "../assets/Group 2607.png";
import Saly16 from "../assets/311.PNG";
import Group3333 from '../assets/Group 3333.png'
import Rectangle from '../assets/Rectangle 1.png'
import elearning from '../assets/e learning.png'
import gdp from '../assets/gdp.png'
import ged from '../assets/ged.png'
import redaction from '../assets/redaction.png'
import virtulab from '../assets/virtulab.png'
import reo from '../assets/reo.png'
import mutation from '../assets/mutation.png'
import { useEffect, useState } from 'react';
import HomeRows from './HomeRows';
import MyParagraph from './MyParagraph';
import MyRow from './MyRow';

function Home() {

    const [isSmall, setIsSmall] = useState(false)
    const [isMedium, setIsMedium] = useState(false)
    const [isLarge, setIsLarge] = useState(false)
    useEffect(() => {

        function handleResize() {
            setIsSmall(window.innerWidth < 540)
            setIsMedium(window.innerWidth < 980)
            setIsLarge(window.innerWidth < 1400)
        }

        setIsSmall(window.innerWidth < 540)
        setIsMedium(window.innerWidth < 980)
        setIsLarge(window.innerWidth < 1400)

        window.addEventListener('resize', handleResize)
        document.getElementById("title").innerHTML = "Accueil ";

    }, [])

    return (
        <>
            <div style={{
                // height: "397vh",
                paddingTop: "16vh",
                paddingBottom: "100px",
                backgroundImage: `url(${background})`,
                backgroundSize: "cover",
                overflow: "hidden",
                paddingLeft: "10vw",
                paddingRight: "10vw"
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly"
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column"
                    }}>

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            overflow: "hidden",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column"

                            }}>
                                <div style={{
                                    fontFamily: "DM Sans",
                                    color: "#0b0d39",
                                    fontSize: isSmall ? "10px" : (isMedium ? "18px" : "50px"),
                                    fontWeight: "bold",
                                    width: "40vw",
                                    minWidth: 400,
                                    textAlign: isSmall ? "center" : " left"

                                }}> <p>Fight plagiarism with</p>
                                    <span style={{ color: "#adcf49" }}>ABCPlagSys</span>-your partner
                                    for academic integrity!
                                </div>
                                <div style={{
                                    color: "#0b0d39",
                                    fontFamily: "DM Sans",
                                    fontSize: isSmall ? "8px" : (isMedium ? "18px" : "20px"),
                                    fontWeight: "bold",
                                    width: isSmall ? "15vw" : "40vw",
                                    minWidth: 400,
                                    textAlign: isSmall ? "center" : " left",
                                    opacity: 0.6,

                                }}>  ABC Plagsys est une plateforme de détection de similitude la plus avancée pour les établissements éducatifs internationaux. Nous aidons les étudiants, les enseignants et les établissements à prévenir le plagiat et à promouvoir l'intégrité académique. Avec notre technologie de pointe et notre équipe de professionnels qualifiés, nous sommes déterminés à fournir une solution efficace pour détecter et prévenir le plagiat.</div>

                                {/* <img src={Path646} style={{ objectFit: "contain", width: "100%", paddingTop: "50px", paddingLeft: isMedium ? "100px" : "" }}></img> */}
                                {/* <div style={{
                                color: "#095782",
                                fontSize: isSmall ? "10px" : (isMedium ? "18px" : "50px"),
                                fontWeight: "bold",
                                width: "40vw",
                                minWidth: 400,
                                textAlign: "center"
                            }}></div> */}
                            </div>
                            <img src={Group3333} style={{ objectFit: "contain", maxWidth: 500, width: "70vw", minWidth: "220px" }}></img>

                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "left",
                            paddingTop: 100,
                            gap: 50,
                            marginLeft: isSmall ? "80px" : ""

                        }}>
                            {/* <button style={{
                                width: isSmall ? "85px" : "182px",
                                height: isSmall ? "30px" : "58px",
                                borderRadius: "35.5px 35.5px 35.5px 9.91px",
                                background: "#356dff",
                                fontFamily: "DM Sans",
                                fontWeight: 500,
                                fontSize: isSmall ? "10px" : "20px",
                                color: "#fff",
                                border: "none"
                            }} onClick={() => window.location.href = '/contact'}>
                                Contactez nous
                            </button>

                            <div style={{
                                width: isSmall ? "80px" : "180px",
                                height: isSmall ? "30px" : "71px",
                                fontFamily: "DM Sans",
                                fontWeight: isSmall ? 200 : 500,
                                fontSize: isSmall ? "9px" : "20px",
                                color: "#0b0d39",
                                backgroundImage: `url(${Rectangle})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                paddingTop: isSmall ? 4 : 20,
                                cursor: "not-allowed",
                                userSelect: 'none'
                            }} onClick={() => window.location.href = '/contact'}>plus d'info </div> */}
                        </div>
                    </div>
                    {/* <img src={Group3333} width="40%" style={{ objectFit: "contain" }}></img> */}

                </div>

                <div className='nn' style={{
                    borderRadius: "20px",
                    background: "rgb(232 232 239)",
                    // opacity: 0.05,
                    filter: "34px"
                }}>
                    <button style={{
                        width: isSmall ? "85px" : "182px",
                        height: isSmall ? "30px" : "58px",
                        borderRadius: "35.5px 35.5px 35.5px 9.91px",
                        background: "rgba(255, 145, 0, 0.2)",
                        fontFamily: "DM Sans",
                        fontWeight: 500,
                        fontSize: isSmall ? "10px" : "20px",
                        color: "#ff9100",
                        border: "none",
                        cursor: " default"


                    }} >
                        feature
                    </button>

                    <p style={{
                        fontFamily: "DM Sans",
                        fontWeight: "bold",
                        fontSize: isSmall ? "30px" : "64px",
                        letterSpacing: "-0.04em",
                        lineHeight: "93px",
                        textAlign: "center",
                        color: "#adcf49"
                    }}>Caractéristiques</p>
                    <p style={{
                        fontFamily: "DM Sans",
                        fontWeight: "bold",
                        fontSize: isSmall ? "30px" : "64px",
                        letterSpacing: "-0.04em",
                        lineHeight: "85px",
                        textAlign: "center",
                        color: " #0b0d39"
                    }}>AbcPlagSys </p>

                    {/* <div style={{
                        width: "161px",
                        height: "50px",
                        borderRadius: "25px",
                        textAlign: "centre",
                        background: "rgba(255, 145, 0, 0.2)"
                    }}>
                        <div style={{
                            fontFamily: "DM Sans",
                            fontWeight: "bold",
                            fontSize: "18px",
                            letterSpacing: " 0.18em",
                            lineHeight: " 93px",
                            textAlign: "centre",
                            color: "#ff9100",
                            paddingTop: isSmall ? 4 : 2
                        }}>feature</div>

                    </div> */}

                    {/* <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "center" }}>
                        <div className="image-container">
                            <img className="circular-image no-pointer resizeButton" src={Blog2} alt={Blog2} />
                        </div>
                        <div className="image-container">
                            <img className="circular-image no-pointer resizeButton" src={Blog3} alt={Blog2} />
                        </div>
                        <div className="image-container">
                            <img className="circular-image no-pointer resizeButton" src={Blog4} alt={Blog2} />
                        </div>
                        <div className="image-container">
                            <img className="circular-image no-pointer resizeButton" src={Blog5} alt={Blog2} />
                        </div>

                    </div> */}


                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "center", gap: "10px" }}>
                        <div style={{ flexWrap: "wrap", flexDirection: "column" }} >
                            <img src={Blog2} style={{ width: "60%", objectFit: "contain" }}></img>
                            <p style={{
                                width: isSmall ? "200px" : "285px",
                                height: isSmall ? "15px" : "40px",
                                fontFamily: "DM Sans",
                                fontWeight: "bold",
                                fontSize: isSmall ? "15px" : "24px",
                                letterSpacing: "-0.03em",
                                lineHeight: isSmall ? "15px" : " 28px",
                                textAlign: "center",
                                marginLeft: isSmall ? "20px" : "",
                                color: "#0b0d39"
                            }}>Technologie avancée
                                de détection de similitude </p>
                            <p style={{
                                width: isSmall ? "200px" : "285px",
                                height: "70px",
                                fontFamily: "DM Sans",
                                fontWeight: "normal",
                                fontSize: "16px",
                                letterSpacing: "-0.03em",
                                lineHeight: " 28px",
                                textAlign: "center",
                                marginLeft: isSmall ? "20px" : "",
                                color: "#0b0d39"
                            }}>Les dernières technologies sont utilisées pour détecter les similitudes dans le document. </p>

                        </div>
                        <div style={{ flexWrap: "wrap", flexDirection: "column" }}>  <img src={Blog3} style={{ width: "68%", objectFit: "contain" }}></img>
                            <p style={{
                                width: "285px",
                                height: "40px",
                                fontFamily: "DM Sans",
                                fontWeight: "bold",
                                fontSize: isSmall ? "15px" : "24px",
                                letterSpacing: "-0.03em",
                                lineHeight: " 28px",
                                textAlign: "center",
                                color: "#0b0d39"
                            }}>Interface utilisateur conviviale  </p>
                            <p style={{
                                width: isSmall ? "200px" : "285px",
                                height: "70px",
                                fontFamily: "DM Sans",
                                fontWeight: "normal",
                                fontSize: isSmall ? "12px" : "16px",
                                letterSpacing: "-0.03em",
                                lineHeight: isSmall ? "15px" : " 28px",
                                textAlign: "center",
                                marginLeft: isSmall ? "40px" : "",
                                color: "#0b0d39"
                            }}>La plateforme est facile à utiliser pour tous les utilisateurs, même ceux qui n'ont pas d'expérience technique. </p>

                        </div>
                        <div style={{ flexWrap: "wrap", flexDirection: "column" }}><img src={Blog4} style={{ width: "60%", objectFit: "contain" }}></img>
                            <p style={{
                                width: "285px",
                                height: "20px",
                                fontFamily: "DM Sans",
                                fontWeight: "bold",
                                fontSize: isSmall ? "15px" : "24px",
                                letterSpacing: "-0.03em",
                                lineHeight: " 28px",
                                textAlign: "center",
                                color: "#0b0d39"
                            }}>Support multi-langues  </p>
                            <p style={{
                                width: isSmall ? "200px" : "285px",
                                height: "70px",
                                fontFamily: "DM Sans",
                                fontWeight: "normal",
                                fontSize: isSmall ? "12px" : "16px",
                                letterSpacing: "-0.03em",
                                lineHeight: " 28px",
                                textAlign: "center",
                                marginLeft: isSmall ? "40px" : "",
                                color: "#0b0d39"
                            }}>La plateforme prend en charge plusieurs langues, permettant d'analyser des documents dans la langue de son choix. </p>

                        </div>
                        <div style={{ flexWrap: "wrap", flexDirection: "column" }}> <img src={Blog5} style={{ width: "60%", objectFit: "contain" }}></img>

                            <p style={{
                                width: "285px",
                                height: "20px",
                                fontFamily: "DM Sans",
                                fontWeight: "bold",
                                fontSize: isSmall ? "15px" : "24px",
                                letterSpacing: "-0.03em",
                                lineHeight: " 28px",
                                textAlign: "center",
                                color: "#0b0d39"
                            }}>Sécurité des données  </p>
                            <p style={{
                                width: isSmall ? "200px" : "285px",
                                height: "70px",
                                fontFamily: "DM Sans",
                                fontWeight: "normal",
                                fontSize: isSmall ? "12px" : "16px",
                                letterSpacing: "-0.03em",
                                lineHeight: " 28px",
                                textAlign: "center",
                                marginLeft: isSmall ? "40px" : "",
                                color: "#0b0d39"
                            }}>La sécurité des données est prise très au sérieux et des mesures de sécurité de pointe sont utilisées pour protéger les données. </p>

                        </div>

                    </div>
                </div>
                {/* <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}> */}
                {/* <HomeRows>
                    <div >
                        <p style={{
                            minWidth: "270px",
                            width: isMedium ? "" : "35vw",
                            // height: "138px",
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                            fontSize: isMedium ? "30px" : "33px",
                            textAlign: "left",
                            color: "#183c70"
                        }}>Améliorer les résultats de recherche</p>
                        <p style={{
                            width: isMedium ? "" : "30vw",
                            minWidth: "270px",
                            fontFamily: "Segoe UI",
                            fontWeight: "normal",
                            fontSize: isMedium ? "15px" : "24px",
                            textAlign: "justify",
                            color: "#2d2c2c"

                        }}>Notre module a été conçu pour faciliter la vie des enseignants qui souhaitent déposer leur demande de mission et suivre l'avancement  de leur dossier en temps réel. Avec cette solution, vous pouvez déposer facilement vos demandes de mission, visualiser l'avancement de votre
                            dossier, recevoir des notifications pour les mises à jour. Découvrez nos fonctionnalités et les avantages que notre module peut vous apporter..
                        </p>
                        <div style={{
                            display: 'flex',
                            justifyContent: "end",
                            paddingRight: 100
                        }}>
                            <div style={{
                                width: "210px",
                                height: "71px",
                                // borderRadius: "35.5px 35.5px 35.5px 9.91px",
                                // background: "transparent",
                                // border: "2px solid #154173",
                                fontFamily: "DM Sans",
                                fontWeight: 500,
                                fontSize: "20px",
                                color: "#0b0d39",
                                backgroundImage: `url(${Rectangle})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                paddingTop: 20,
                                cursor: "not-allowed",
                                userSelect: 'none'

                            }}>En Savoir Plus</div>
                        </div>
                    </div>
                    <div>
                        <img style={{ width: isMedium ? "35vw" : "70%" }} src={Saly10}></img>
                    </div>
                </HomeRows> */}
                {/* </div> */}

                {/* <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center", marginTop: 50 }}> */}
                <HomeRows>

                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", flexDirection: "column", width: isMedium ? "" : "33vw", minWidth: isSmall ? "" : "250px" }}>
                        <p style={{
                            fontFamily: "DM Sans",
                            fontWeight: "bold",
                            fontSize: isSmall ? "30px" : "64px",
                            letterSpacing: "-0.04em",
                            lineHeight: "93px",
                            textAlign: isSmall ? "center" : "left", marginRight: isSmall ? "20px" : "",
                            color: "#adcf49"
                        }}>Avantage</p>
                        <p style={{
                            fontFamily: "DM Sans",
                            fontWeight: "bold",
                            fontSize: isSmall ? "30px" : "64px",
                            letterSpacing: "-0.04em",
                            lineHeight: "85px", marginRight: isSmall ? "20px" : "",
                            textAlign: isSmall ? "center" : "left",
                            color: " #0b0d39"
                        }}>AbcPlagSys </p>
                        <p style={{
                            width: isSmall ? "200px" : "285px",
                            height: "70px",
                            fontFamily: "DM Sans",
                            fontWeight: "normal",
                            fontSize: isSmall ? "12px" : "16px",
                            letterSpacing: "-0.03em",
                            lineHeight: " 28px", marginLeft: isSmall ? "110px" : "",
                            textAlign: isSmall ? "center" : "left",
                            color: "#0b0d39"
                        }}>ABC Plagsys propose une gamme de services pour répondre aux besoins des étudiants et des établissements éducatifs. Les utilisateurs peuvent envoyer leurs documents directement au système pour une analyse rapide et précise, ou utiliser l'adresse d'analyse pour une intégration transparente</p>

                        <div style={{
                            display: 'flex',
                            padding: "150px",
                            paddingRight: 200
                        }}>
                            <button style={{
                                width: isSmall ? "85px" : "182px",
                                height: isSmall ? "30px" : "58px",
                                borderRadius: "35.5px 35.5px 35.5px 9.91px",
                                background: "#adcf49",
                                fontFamily: "DM Sans",
                                fontWeight: 500,
                                fontSize: isSmall ? "10px" : "20px",
                                color: "#fff",
                                border: "none"
                            }} onClick={() => window.location.href = '/Avantages'}>
                                plus d'info
                            </button>
                        </div>
                    </div>
                    <div >
                        <img style={{ width: "32vw" }} src={Saly16}></img>
                    </div>
                </HomeRows>

                <div className='nn' style={{
                    borderRadius: "20px",
                    background: "rgb(232 232 239)",
                    // opacity: 0.05,
                    filter: "34px"
                }}>
                    <button style={{
                        width: isSmall ? "85px" : "182px",
                        height: isSmall ? "30px" : "58px",
                        borderRadius: "35.5px 35.5px 35.5px 9.91px",
                        background: "rgba(255, 145, 0, 0.2)",
                        fontFamily: "DM Sans",
                        fontWeight: 500,
                        fontSize: isSmall ? "10px" : "20px",
                        color: "#ff9100",
                        border: "none",
                        cursor: " default"
                    }} >
                        feature
                    </button>
                    <p style={{
                        fontFamily: "DM Sans",
                        fontWeight: "bold",
                        fontSize: isSmall ? "30px" : "64px",
                        letterSpacing: "-0.04em",
                        lineHeight: "93px",
                        textAlign: "center",
                        color: "#adcf49"
                    }}>fonctionnalités</p>
                    <p style={{
                        fontFamily: "DM Sans",
                        fontWeight: "bold",
                        fontSize: isSmall ? "30px" : "64px",
                        letterSpacing: "-0.04em",
                        lineHeight: "85px",
                        textAlign: "center",
                        color: " #0b0d39"
                    }}>AbcPlagSys </p>
                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-around" }}>
                        <img src={Blo1} style={{ width: "25%", minWidth: "200px", objectFit: "contain" }}></img>
                        <img src={Blo2} style={{ width: "25%", minWidth: "200px", objectFit: "contain" }}></img>
                        <img src={Blo3} style={{ width: "25%", minWidth: "200px", objectFit: "contain" }}></img>
                        <img src={Blo4} style={{ width: "25%", minWidth: "200px", objectFit: "contain" }}></img>
                    </div>

                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: 100
                }}>

                    <button style={{
                        width: isSmall ? "85px" : "182px",
                        height: isSmall ? "30px" : "58px",
                        borderRadius: "35.5px 35.5px 35.5px 9.91px",
                        background: "rgba(255, 145, 0, 0.2)",
                        fontFamily: "DM Sans",
                        fontWeight: 500,
                        fontSize: isSmall ? "10px" : "20px",
                        color: "#ff9100",
                        border: "none",
                        cursor: " default"
                    }} >
                        feature
                    </button>
                    <p style={{
                        fontFamily: "DM Sans",
                        fontWeight: "bold",
                        fontSize: isSmall ? "30px" : "64px",
                        letterSpacing: "-0.04em",
                        lineHeight: "93px",
                        textAlign: "center",
                        color: "#adcf49"
                    }}>Services</p>
                    <p style={{
                        fontFamily: "DM Sans",
                        fontWeight: "bold",
                        fontSize: isSmall ? "30px" : "64px",
                        letterSpacing: "-0.04em",
                        lineHeight: "85px",
                        textAlign: "center",
                        color: " #0b0d39"
                    }}>AbcPlagSys </p>
                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-around" }}>
                        <img src={Bl1} style={{ width: "25%", minWidth: "200px", objectFit: "contain" }}></img>
                        <img src={Bl2} style={{ width: "25%", minWidth: "200px", objectFit: "contain" }}></img>
                        <img src={Bl3} style={{ width: "25%", minWidth: "200px", objectFit: "contain" }}></img>

                    </div>

                </div>
                {/* </div> */}

                {/* <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center", marginTop: 50 }}> */}
                <div>
                    <img src={B1} style={{ width: "100%", minWidth: "200px", objectFit: "contain" }}></img>
                </div>

                {/* <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: 100
                }}>
                    <p style={{
                        width: "471px",
                        height: "164px",
                        fontFamily: "Poppins",
                        fontWeight: "normal",
                        fontSize: isSmall ? "15px" : isMedium ? "30px" : "50px",
                        textAlign: "center",
                        color: "#183c70"
                    }}>Nos Solutions Complimentaires</p>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: 5,
                        flexWrap: "wrap",
                        fontSize: 14,
                        width: "80vw"
                    }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", width: "200px", height: isSmall ? "" : "250px" }}>
                            <img style={{ height: isSmall ? "8vh" : "10vh", objectFit: "contain" }} src={elearning}></img>
                            <p className='solutions-names'>E-learning</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", width: "200px", height: isSmall ? "" : "250px" }}>
                            <img style={{ height: isSmall ? "8vh" : "10vh", objectFit: "contain" }} src={gdp}></img>
                            <p className='solutions-names'>Gestion de projets</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", width: "200px", height: isSmall ? "" : "250px" }}>
                            <img style={{ height: isSmall ? "8vh" : "10vh", objectFit: "contain" }} src={ged}></img>
                            <p className='solutions-names'>GED</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", width: "200px", height: isSmall ? "" : "250px" }}>
                            <img style={{ height: isSmall ? "8vh" : "10vh", objectFit: "contain" }} src={virtulab}></img>
                            <p className='solutions-names'>Virtulab</p>
                        </div>
                        {/* <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", width: "200px", height: "250px" }}>
                            <img style={{ height: "10vh", objectFit: "contain" }} src={redaction}></img>
                            <p className='solutions-names'>Gestion des missions et stages</p>
                        </div> */}
                {/* <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", width: "200px", height: isSmall ? "" : "250px" }}>
                            <img style={{ height: isSmall ? "8vh" : "10vh", objectFit: "contain" }} src={reo}></img>
                            <p className='solutions-names'>Reo</p>
                        </div> */}
                {/* <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", width: "200px", height: "250px" }}>
                            <img style={{ height: "10vh", objectFit: "contain" }} src={mutation}></img>
                            <p className='solutions-names'>Mutation</p>
                        </div> */}
                {/* </div >  */}
                {/* </div > */}

            </div >

            <Footer />
        </>
    )
}

export default Home