import React from 'react'
import Footer from '../components/Footer';
import background from "../assets/homebackground.png";
import contact from '../assets/contact.png'
import Contactform from './Contactform';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Partenaires from './Partenaires';
import { useEffect, useState } from 'react';
import Offre from './Offre';

function Contact() {
    const [isSmall, setIsSmall] = useState(false)
    const [isMedium, setIsMedium] = useState(false)
    const [isLarge, setIsLarge] = useState(false)
    useEffect(() => {

        function handleResize() {
            setIsSmall(window.innerWidth < 540)
            setIsMedium(window.innerWidth < 980)
            setIsLarge(window.innerWidth < 1400)
        }

        setIsSmall(window.innerWidth < 540)
        setIsMedium(window.innerWidth < 980)
        setIsLarge(window.innerWidth < 1400)

        window.addEventListener('resize', handleResize)
        document.getElementById("title").innerHTML = "Contact";

    }, [])
    return (
        <>
            <div style={{
                // maxHeight: "397vh",
                // height: "100vh",
                paddingTop: "16vh",
                paddingBottom: "100px",
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                paddingLeft: "14vw",
                paddingRight: "14vw"
            }}>
                <Tabs
                    defaultActiveKey="contact us"
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                >
                    <Tab eventKey="partenaires" title="Partenaires">
                        <Partenaires />
                    </Tab>
                    <Tab eventKey="offre" title="Offre">
                        <Offre />
                    </Tab>
                    <Tab eventKey="contact us" title="Contactez-Nous">
                        <div style={{ display: 'flex', flexWrap: "wrap", justifyContent: "center", alignItems: "center", flexDirection: "row", gap: 100 }}>
                            <div>
                                <p style={{
                                    // width: "659px",
                                    // height: "138px",
                                    fontFamily: "Poppins",
                                    fontWeight: "normal",
                                    fontSize: isSmall ? "20px" : "50px",
                                    textAlign: "left",
                                    color: "#183c70",
                                    marginLeft: isSmall ? "50px" : "100px",
                                    marginBottom: "50px"
                                }}>Contactez-Nous</p>
                                <img src={contact} style={{ marginTop: "-100px", width: "25vw" }}></img>
                            </div>
                            <div style={{ width: '50%' }}>
                                <div style={{
                                    width: "42vw",
                                    height: "487px",
                                    borderRadius: "32px",
                                    background: "transparent",
                                    boxShadow: "3px 5px 20px rgba(0, 0, 0, 0.16)",
                                    // background: "linear - gradient(180deg, rgba(0, 103, 142, 1) 0 %, rgba(37, 38, 96, 1) 100 %)",
                                    backdropFilter: "blur(50px)",
                                    // --webkit-backdrop-filter: blur(50px)
                                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                                    background: "linear-gradient(180deg, rgba(0,103,142,0.19931722689075626) 0%, rgba(37,38,96,0.20211834733893552) 100%)",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <div style={{ width: "90%", height: 391, display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between"
                                        }}>
                                            <Contactform width={296} placeholder={"your name"} />
                                            <Contactform width={296} placeholder={"exemple@sthing.tt"} />
                                        </div>
                                        <Contactform placeholder={"subject"} />
                                        <div style={{
                                            // width: "100%",
                                            backgroundColor: "white",
                                            borderRadius: 15,
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "start",
                                            alignItems: "center",
                                            position: "relative",
                                            fontSize: 12,
                                            padding: 10
                                        }}>
                                            <textarea style={{
                                                minHeight: 153,
                                                maxHeight: 250,
                                                outline: 'none',
                                                border: 'none',
                                                fontFamily: 'Quicksand',
                                                fontWeight: 'normal',
                                                fontSize: 14,
                                                color: '#7c7c7c',
                                                opacity: 0.8,
                                                width: "100%",
                                                height: "100%"
                                            }} placeholder={"ex...."}></textarea>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <button style={{
                                                width: '296px',
                                                borderRadius: '15px',
                                                background: 'linear-gradient(180deg, rgba(39,37,94,1) 0%, rgba(3,103,143,1) 100%)',
                                                color: 'white',
                                                height: '44px',
                                                border: '0',
                                                fontWeight: 'bold',
                                                fontSize: '15px'
                                            }}>envoyer</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    {/* <Tab eventKey="contact" title="Contact" disabled>
                    <Sonnet />
                </Tab> */}
                </Tabs>


            </div >
            <Footer />
        </>
    )
}

export default Contact