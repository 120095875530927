import React from 'react'
import Footer from '../components/Footer';
import background from "../assets/homebackground.png";
import n1 from "../assets/Group 2403.png"
import n2 from "../assets/Group 2415.png"
import n3 from "../assets/Group 2418.png"
import n4 from "../assets/Group 2419.png"
import n5 from "../assets/Group 2421.png"
import saly from "../assets/Group 2402.png"
import ParagraphStyle from './ParagraphStyle';
import { useEffect, useState } from 'react';
import MyRow from './MyRow';
import MyParagraph from './MyParagraph';

function Services() {
    const [isSmall, setIsSmall] = useState(false)
    const [isMedium, setIsMedium] = useState(false)
    const [isLarge, setIsLarge] = useState(false)
    useEffect(() => {

        function handleResize() {
            setIsSmall(window.innerWidth < 540)
            setIsMedium(window.innerWidth < 980)
            setIsLarge(window.innerWidth < 1400)
        }

        setIsSmall(window.innerWidth < 540)
        setIsMedium(window.innerWidth < 980)
        setIsLarge(window.innerWidth < 1400)

        window.addEventListener('resize', handleResize)
        document.getElementById("title").innerHTML = " Services";
    }, [])

    return (
        <>
            <div style={{
                paddingTop: "16vh",
                paddingBottom: "100px",
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                overflow: "hidden",
                paddingLeft: "14vw",
                paddingRight: "14vw"
            }}>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "40px",
                    width: "80%", marginBottom: "35px"
                }}>


                    <p style={{
                        width: isSmall ? "80vw" : "",
                        // height: "170px",
                        fontFamily: "DM Sans",
                        fontWeight: 500,
                        fontSize: isSmall ? "15px" : "20px",
                        lineHeight: isSmall ? "30px" : " 75px",
                        textAlign: "center",
                        color: " #0b0d39",
                        opacity: 0.6

                    }}>
                        Nous offrons une large gamme de services pour répondre à vos besoins en matière de détection de similitude, y compris :
                    </p>


                    <div className='vv' style={{
                        border: " 2px solid #7b68ee", borderRadius: "50px",
                        height: " 84px", width: isSmall ? "80vw" : ""


                    }}>
                        <img src={n1} style={{ objectFit: "contain", width: "50px", marginTop: "-35px" }}></img>
                        <p style={{
                            fontFamily: "DM Sans",
                            fontWeight: "bold",
                            fontSize: isSmall ? "12px" : "20px",
                            lineHeight: isSmall ? "10px" : "15px",
                            textAlign: " left",
                            color: " #0b0d39"
                        }}> Analyse de documents académiques pour les établissements éducatifs. </p>
                    </div>
                    <div className='vv' style={{
                        border: " 2px solid #FFC53F", borderRadius: "50px",
                        height: " 84px", width: isSmall ? "80vw" : ""


                    }}>
                        <img src={n2} style={{ objectFit: "contain", width: "50px", marginTop: "-35px" }}></img>
                        <p style={{
                            fontFamily: "DM Sans",
                            fontWeight: "bold",
                            fontSize: isSmall ? "12px" : "20px",
                            lineHeight: isSmall ? "10px" : "15px",
                            textAlign: " left",
                            color: " #0b0d39"
                        }}> Assistance pour les utilisateurs de la plateforme. </p>
                    </div>
                    <div className='vv' style={{
                        border: " 2px solid #356DFF", borderRadius: "50px",
                        height: " 84px", width: isSmall ? "80vw" : ""


                    }}>
                        <img src={n3} style={{ objectFit: "contain", width: "50px", marginTop: "-35px" }}></img>
                        <p style={{
                            fontFamily: "DM Sans",
                            fontWeight: "bold",
                            fontSize: isSmall ? "12px" : "20px",
                            lineHeight: isSmall ? "10px" : "15px",
                            textAlign: " left",
                            color: " #0b0d39"
                        }}> Possibilité de supprimer des documents ou de rendre votre document privé. </p>
                    </div>
                    <div className='vv' style={{
                        border: " 2px solid #ADCF49", borderRadius: "50px",
                        height: " 84px", width: isSmall ? "80vw" : ""


                    }}>
                        <img src={n4} style={{ objectFit: "contain", width: "50px", marginTop: "-35px" }}></img>
                        <p style={{
                            fontFamily: "DM Sans",
                            fontWeight: "bold",
                            fontSize: isSmall ? "10px" : "20px",
                            lineHeight: isSmall ? "10px" : "17px",
                            textAlign: " left",
                            color: " #0b0d39"
                        }}> Possibilité de demander une re-analyse du document si vous avez apporté des modifications. </p>
                    </div>
                    <div className='vv' style={{
                        border: " 2px solid #356DFF",
                        borderRadius: "50px",
                        height: " 84px",
                        width: isSmall ? "80vw" : ""


                    }}>
                        <img src={n5} style={{ objectFit: "contain", width: "50px", marginTop: "-35px" }}></img>
                        <p style={{
                            fontFamily: "DM Sans",
                            fontWeight: "bold",
                            fontSize: isSmall ? "12px" : "20px",
                            lineHeight: isSmall ? "10px" : "15px",
                            textAlign: " left",
                            color: " #0b0d39"
                        }}> Possibilité de télécharger des rapports d'analyse pour référence future. </p>
                    </div>
                </div>
                <img style={{ width: isMedium ? "30vw" : "45vw" }} src={saly}></img>


            </div >
            <Footer />
        </>
    )
}

export default Services