import React from 'react'
import { useEffect, useState } from 'react';

function HomeRows({ children }) {

    const [isSmall, setIsSmall] = useState(false)
    const [isMedium, setIsMedium] = useState(false)
    const [isLarge, setIsLarge] = useState(false)
    useEffect(() => {

        function handleResize() {
            setIsSmall(window.innerWidth < 540)
            setIsMedium(window.innerWidth < 980)
            setIsLarge(window.innerWidth < 1400)
        }

        setIsSmall(window.innerWidth < 540)
        setIsMedium(window.innerWidth < 980)
        setIsLarge(window.innerWidth < 1400)

        window.addEventListener('resize', handleResize)

    }, [])

    return (
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly", alignItems: "center", marginTop: "100px" }}>
            {children}
        </div>
    )
}

export default HomeRows