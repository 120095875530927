import React from 'react'

function Contactform({ placeholder, width }) {
    return (
        <div style={{
            width: width,
            height: 50,
            backgroundColor: "white",
            borderRadius: 15,
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            position: "relative",
            fontSize: 12,
            padding: 10
        }}>
            <input type='text' name="name" className='input' placeholder={placeholder}
                style={{
                    outline: 'none',
                    // width: 250,
                    border: 'none',
                    fontFamily: 'Quicksand',
                    fontWeight: 'normal',
                    fontSize: 14,
                    // textAlign: 'left',
                    color: '#7c7c7c',
                    opacity: 0.8,
                    width: "100%",
                    height: "100%"
                }} />
        </div>
    )
}

export default Contactform