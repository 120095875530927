import React from 'react'
import MyRow from './MyRow'
import background from "../assets/homebackground.png";
import Footer from '../components/Footer';
import ParagraphStyle from './ParagraphStyle';
import demo from '../assets/Object Image.png'
import { useEffect, useState } from 'react';

function Demo() {
    const [isSmall, setIsSmall] = useState(false)
    const [isMedium, setIsMedium] = useState(false)
    const [isLarge, setIsLarge] = useState(false)
    useEffect(() => {

        function handleResize() {
            setIsSmall(window.innerWidth < 540)
            setIsMedium(window.innerWidth < 980)
            setIsLarge(window.innerWidth < 1400)
        }

        setIsSmall(window.innerWidth < 540)
        setIsMedium(window.innerWidth < 980)
        setIsLarge(window.innerWidth < 1400)

        window.addEventListener('resize', handleResize)
        document.getElementById("title").innerHTML = "Démonstration";

    }, [])
    return (
        <>
            <div style={{
                paddingTop: "16vh",
                paddingBottom: "100px",
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                overflow: "hidden",
                paddingLeft: "14vw",
                paddingRight: "14vw"
            }}>
                <MyRow>
                    <ParagraphStyle>
                        "Vous voulez voir AbcPlagSys en action? Inscrivez-vous pour une démonstration en direct de notre plateforme. Découvrez comment nous pouvons vous aider à suivre et à mesurer l'impact de vos travaux de recherche. Nous vous montrerons comment utiliser nos outils pour améliorer la performance de votre recherche et maximiser son impact."
                    </ParagraphStyle>

                    <img style={{ width: isMedium ? "35vw" : "20vw" }} src={demo}></img>
                </MyRow>

            </div>
            <Footer />
        </>
    )
}

export default Demo