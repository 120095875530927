import React from 'react';
import Footer from './Footer'
import background from "../assets/homebackground.png";
import avantages from '../assets/avantages.png';
import avant from '../assets/44.png';
import ava from '../assets/Group21.png';
import avan from '../assets/Group 2357.png';

import { useEffect, useState } from 'react';
import ParagraphStyle from './ParagraphStyle';
import MyRow from './MyRow';
import MyParagraph from './MyParagraph';

function Avantages() {
    const [isSmall, setIsSmall] = useState(false)
    const [isMedium, setIsMedium] = useState(false)
    const [isLarge, setIsLarge] = useState(false)
    useEffect(() => {

        function handleResize() {
            setIsSmall(window.innerWidth < 540)
            setIsMedium(window.innerWidth < 980)
            setIsLarge(window.innerWidth < 1400)
        }

        setIsSmall(window.innerWidth < 540)
        setIsMedium(window.innerWidth < 980)
        setIsLarge(window.innerWidth < 1400)

        window.addEventListener('resize', handleResize)
        document.getElementById("title").innerHTML = "Avantages";

    }, [])
    return (
        <>
            <div style={{
                paddingTop: "20vh",
                paddingBottom: "100px",
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                overflow: "hidden",
                paddingLeft: "14vw",
                paddingRight: "14vw"

            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "40px",
                    // paddingLeft: isMedium ? "10vw" : isLarge ? "15vw" : "",
                    // paddingRight: "50vw",
                    width: "100%"
                }}>

                    {/* <ParagraphStyle style={{ fontWeight: "bold" }}>Voici les avantages clés de notre module ,
                        présentés sous forme commerciale :</ParagraphStyle> */}
                    <MyRow>
                        <div className='vv1' style={{
                            border: " 2px solid #ffc53f"
                        }}>
                            <  MyParagraph style={{ width: isSmall ? "" : "40vw" }}
                                title="Évitez le plagiat "
                                description="Le système de détection de similitude garanti l'intégrité de travail académique." >
                            </ MyParagraph >
                        </div>
                        <img style={{ width: "10vw" }} src={avantages}></img>

                    </MyRow>
                    <MyRow>

                        <div className='vv1' style={{ border: " 2px solid #ADCF49" }}>
                            < MyParagraph style={{ width: isSmall ? "" : "40vw" }}
                                title="Améliorez la qualité du travail  "
                                description="Des commentaires et des suggestions pour améliorer le travail."
                            >
                            </MyParagraph>

                        </div>
                        <img style={{ width: "12vw" }} src={avant}></img>
                    </MyRow>
                    <MyRow>
                        <div className='vv1' style={{ border: " 2px solid #FFC53F" }}>
                            <MyParagraph style={{ width: isSmall ? "" : "40vw" }}
                                title="Gagnez du temps "
                                description=" Le système analyse rapidement les documents, permettant de se concentrer sur d'autres tâches."
                            >
                            </MyParagraph >
                        </div>
                        <img style={{ width: "12vw" }} src={ava}></img>
                    </MyRow>
                    <MyRow>


                        <div className='vv1' style={{ border: " 2px solid #ADCF49" }}>
                            <MyParagraph style={{ width: isSmall ? "" : "40vw" }}
                                title="Accédez au service de n'importe où "
                                description="La plateforme en ligne permet d'analyser les documents de n'importe où dans le monde."
                            >
                            </MyParagraph >
                        </div>
                        <img style={{ width: "12vw" }} src={avan}></img>
                    </MyRow>

                </div>

            </div >
            <Footer />
        </>
    )
}

export default Avantages