import React from 'react'

import { useEffect, useState } from 'react';

export default function MyRow({ children }) {

    const [isSmall, setIsSmall] = useState(false)
    const [isMedium, setIsMedium] = useState(false)
    const [isLarge, setIsLarge] = useState(false)
    useEffect(() => {

        function handleResize() {
            setIsSmall(window.innerWidth < 540)
            setIsMedium(window.innerWidth < 980)
            setIsLarge(window.innerWidth < 1400)
        }

        setIsSmall(window.innerWidth < 540)
        setIsMedium(window.innerWidth < 980)
        setIsLarge(window.innerWidth < 1400)

        window.addEventListener('resize', handleResize)

    }, [])
    return (
        <div style={{ display: 'flex', flexDirection: "row", flexWrap: "wrap" }}>
            <div style={{
                minWidth: '200px',
                width: isMedium ? "90vw" : "35vw",
                display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center"
            }}>
                {children[0]}
            </div>
            <div style={{ minWidth: '200px', width: isMedium ? "90vw" : "35vw", display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                {children[1]}
            </div>
        </div>
    )
}
