import React from 'react'
import { useEffect, useState } from 'react';

function ParagraphStyle({ children, style = {} }) {

    const [isSmall, setIsSmall] = useState(false)
    const [isMedium, setIsMedium] = useState(false)
    const [isLarge, setIsLarge] = useState(false)
    useEffect(() => {

        function handleResize() {
            setIsSmall(window.innerWidth < 540)
            setIsMedium(window.innerWidth < 980)
            setIsLarge(window.innerWidth < 1400)
        }

        setIsSmall(window.innerWidth < 540)
        setIsMedium(window.innerWidth < 980)
        setIsLarge(window.innerWidth < 1400)

        window.addEventListener('resize', handleResize)

    }, [])

    return (
        <p style={{
            fontFamily: "Segoe UI",
            fontWeight: "normal",
            fontSize: isSmall ? "13px" : isMedium ? "15px" : "26px",
            textAlign: "justify",
            color: "#2d2c2c",
            // fontWeight: 200,
            lineHeight: isSmall ? "30px" : "45px",
            ...style
        }}>{children}</p>

    )
}

export default ParagraphStyle