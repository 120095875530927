import React from 'react'
import background from "../assets/homebackground.png";
import Footer from './Footer';
import '../App.css';
import MyRow from './MyRow';
import feature1 from '../assets/Group 792.png'
import feature2 from '../assets/Group 877.png'
import feature3 from '../assets/Group 915.png'
import feature4 from '../assets/Group 1615.png'
import feature5 from '../assets/Group 1097.png'
import feature6 from '../assets/Group 1647.png'
import feature7 from '../assets/Group 2606.png'
import ParagraphStyle from './ParagraphStyle';
import MyParagraph2 from './MyParagraph2';
import { useEffect, useState } from 'react';

function Features() {
    const [isSmall, setIsSmall] = useState(false)
    const [isMedium, setIsMedium] = useState(false)
    const [isLarge, setIsLarge] = useState(false)
    useEffect(() => {

        function handleResize() {
            setIsSmall(window.innerWidth < 540)
            setIsMedium(window.innerWidth < 980)
            setIsLarge(window.innerWidth < 1400)
        }

        setIsSmall(window.innerWidth < 540)
        setIsMedium(window.innerWidth < 980)
        setIsLarge(window.innerWidth < 1400)

        window.addEventListener('resize', handleResize)
        document.getElementById("title").innerHTML = "Fonctionnalités";

    }, [])
    return (
        <>
            <div style={{
                paddingTop: "16vh",
                paddingBottom: "100px",
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                overflow: "hidden",
                paddingLeft: "14vw",
                paddingRight: "14vw", gap: "40px"

            }}>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "40px",
                    // paddingLeft: isMedium ? "10vw" : isLarge ? "15vw" : "",
                    // paddingRight: "50vw",
                    width: "100%"
                }}>
                    <img style={{
                        width: "15vw", display: "block",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }} src={feature1}></img>
                    <div>
                        <div className='vv' style={{ width: isSmall ? "85vw" : isMedium ? "85vw" : "75VW", border: " 1px solid #ffc53f", borderRadius: "100px" }}>
                            <div style={{
                                flexWarp: "nowrap",
                                display: "flex",
                                gap: "20px"
                            }}>
                                <MyParagraph2
                                    title="Analyse de similitude en ligne "
                                    description="Ajout des documents sur la plateforme en ligne pour une analyse rapide et précise."
                                />
                                <div style={{
                                    borderLeft: "6px solid #ffc53f",
                                    height: "200px"
                                }}></div>
                                <MyParagraph2
                                    title="Assistant personnel  "
                                    description="Une équipe d'experts est disponible pour aider à tout moment et répondre à toutes les questions."
                                />
                            </div>
                        </div>

                    </div>
                    <img style={{
                        width: "18vw", display: "block",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }} src={feature2}></img>
                    <div>
                        <div className='vv' style={{ width: isSmall ? "85vw" : isMedium ? "85vw" : "75VW", border: " 1px solid #7EC68F", borderRadius: "100px" }}>
                            <div style={{
                                flexWarp: "nowrap",
                                display: "flex",
                                gap: "20px"
                            }}>
                                <MyParagraph2
                                    title="Confidentialité"
                                    description="Les documents peuvent être rendus privés pour empêcher quiconque d'y accéder. "
                                />
                                <div style={{
                                    borderLeft: "6px solid #7EC68F",
                                    height: "200px"
                                }}></div>
                                <MyParagraph2
                                    title="Rapport détaillé "
                                    description="Un rapport détaillé est fourni sur les similitudes trouvées dans le document."
                                />
                            </div>
                        </div>

                    </div>
                    <img style={{
                        width: "18vw", display: "block",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }} src={feature3}></img>
                    <div>
                        <div className='vv' style={{ width: isSmall ? "85vw" : isMedium ? "85vw" : "75VW", border: " 1px solid #356DFF", borderRadius: "100px" }}>
                            <div style={{
                                flexWarp: "nowrap",
                                display: "flex",
                                gap: "20px"
                            }}>
                                <MyParagraph2
                                    title="Analyse directe "
                                    description="Les documents peuvent être envoyés directement à l'adresse d'analyse pour une analyse rapide et précise."
                                />
                                <div style={{
                                    borderLeft: "6px solid #356DFF",
                                    height: "200px"
                                }}></div>
                                <MyParagraph2
                                    title=" Analyse automatisée des documents académiques"
                                    description="Le système de détection de similitude utilise des algorithmes avancés pour analyser automatiquement les documents téléchargés sur la plateforme."
                                />
                            </div>
                        </div>

                    </div>

                    <img style={{
                        width: "18vw", display: "block",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }} src={feature4}></img>
                    <div>
                        <div className='vv' style={{ width: isSmall ? "85vw" : isMedium ? "85vw" : "75VW", border: " 1px solid #FFB300", borderRadius: "100px" }}>
                            <div style={{
                                flexWarp: "nowrap",
                                display: "flex",
                                gap: "20px"
                            }}>
                                <MyParagraph2
                                    title="Rapports de similitude détaillés"
                                    description=" Les résultats d'analyse comprennent un rapport détaillé montrant les passages similaires détectés, les sources trouvées et les pourcentages de similitude correspondants."
                                />
                                <div style={{
                                    borderLeft: "6px solid #FFB300",
                                    height: "200px"
                                }}></div>
                                <MyParagraph2
                                    title="Options de confidentialité flexibles"
                                    description="Rendre les documents privés pour empêcher leur partage, ou suppression complètement de la plateforme."
                                />
                            </div>
                        </div>

                    </div>

                    <img style={{
                        width: "15vw", display: "block",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }} src={feature5}></img>
                    <div>
                        <div className='vv' style={{ width: isSmall ? "85vw" : isMedium ? "85vw" : "75VW", border: " 1px solid #7EC68F", borderRadius: "100px" }}>
                            <div style={{
                                flexWarp: "nowrap",
                                display: "flex",
                                gap: "20px"
                            }}>
                                <MyParagraph2
                                    title="Assistance en temps réel"
                                    description="Equipe de support est disponible en tout temps pour répondre à vos questions et vous guider à travers le processus d'analyse."
                                />
                                <div style={{
                                    borderLeft: "6px solid #7EC68F",
                                    height: "200px"
                                }}></div>
                                <MyParagraph2
                                    title="Re-analyse des documents"
                                    description="Si vous apportez des modifications à un document déjà analysé, vous pouvez demander une re-analyse pour vous assurer que toutes les similitudes ont été détectées."
                                />
                            </div>
                        </div>

                    </div>

                    <img style={{
                        width: "20vw", display: "block",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }} src={feature6}></img>
                    <div>
                        <div className='vv' style={{ width: isSmall ? "85vw" : isMedium ? "85vw" : "75VW", border: " 1px solid #356DFF", borderRadius: "100px" }}>
                            <div style={{
                                flexWarp: "nowrap",
                                display: "flex",
                                gap: "20px"
                            }}>
                                <MyParagraph2
                                    title="Accès à distance"
                                    description="L’adresse d’analyse à distance vous permet de soumettre des documents pour analyse sans même vous connecter à la plateforme."
                                />
                                <div style={{
                                    borderLeft: "6px solid #356DFF",
                                    height: "200px"
                                }}></div>
                                <MyParagraph2
                                    title="Interface utilisateur conviviale"
                                    description="la plateforme est conçue pour être facile à utiliser, avec des instructions claires pour vous guider tout au long du processus."
                                />
                            </div>
                        </div>

                    </div>

                    < img style={{
                        width: "18vw", display: "block",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }} src={feature7}  ></img>
                    <div>
                        <div className='vv' style={{ width: isSmall ? "85vw" : isMedium ? "85vw" : "75VW", border: " 1px solid #ffc53f", borderRadius: "100px" }}>
                            <div style={{
                                flexWarp: "nowrap",
                                display: "flex",
                                gap: "20px"
                            }}>
                                <MyParagraph2
                                    title="Compatibilité avec différents formats de fichiers"
                                    description="Nous acceptons un large éventail de formats de fichiers, y compris les fichiers Word, PDF, TXT, HTML et bien d'autres encore."
                                />
                                <div style={{
                                    borderLeft: "6px solid #ffc53f",
                                    height: "200px"
                                }}></div>
                                <MyParagraph2
                                    title="Analyse multilingue"
                                    description="Notre système est capable de détecter les similitudes dans différentes langues, ce qui en fait une solution internationale pour les établissements éducatifs du monde entier."
                                />
                            </div>
                        </div>

                    </div>











                </div>

            </div >

            <Footer />
        </>
    )
}

export default Features