import React, { useState, useEffect } from 'react';
import background from "../assets/homebackground.png";
import AmirKboubi from "../assets/Amir Kboubi.jpg"
import HediZAIEM from "../assets/Hedi ZAIEM.jpeg";
import YayaBODIAN from "../assets/Yaya BODIAN.png";
import DanielGOUADAIN from "../assets/Daniel GOUADAIN.png";
import team2 from "../assets/Saly-38.png";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import cvzaiem from '../assets/pdfs/Hedi ZAIEM.pdf'
import cvyaya from '../assets/pdfs/yaya pic.pdf'
import cvdaniel from '../assets/pdfs/Daniel GOUADAIN.pdf'
import Footer from './Footer';

import feres from '../assets/team/feres.jpg'
import dhia from '../assets/team/dhia.jpg'
import dx from '../assets/team/dx.jpg'
import abrar from '../assets/team/abrar.jpg'
import amira from '../assets/team/amira.jpg'
import nesrine from '../assets/team/nesrine.jpg'
import siwar from '../assets/team/siwar.jpg'
import wouroud from '../assets/team/wouroud1.jpg'
import adem from '../assets/team/adem.jpg'
import benHamida from '../assets/team/benHamida.jpg'
import GroupPicture from './GroupPicture';


function MyVerticallyCenteredModal(props) {
    useEffect(() => {
        document.getElementById("title").innerHTML = "Équipe ";

    }, [])
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Mohamed El Hedi ZAIEM
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <h4>Centered Modal</h4> */}
                <p style={{ textAlign: "justify" }}>
                    Il est né le 14 juillet 1952 à Ras Jebel. Après des études primaires à Ras Jebel et secondaires au Lycée de Bizerte, il obtient en 1973 une licence en sciences économiques de la Faculté de Droit et des Sciences Politiques et Economiques de Tunis. Après une année comme professeur au Lycée Technique de Radès, il intègre l’Ecole Nationale de la Statistique et de l’Administration Economique de Paris où il obtient le diplôme d’Ingénieur Statisticien Economiste en juin 1977. Il prépare en parallèle un troisième cycle à l’Université de Paris I Panthéon Sorbonne où il obtient un Diplôme d’Etudes Supérieure d’Econométrie. Il prépare et obtient en 1995 un Doctorat d’Etat en Sciences Economiques de l’Université de Tunis.
                    <br />
                    <br />
                    Il a fait le plus clair de sa carrière comme assistant puis maître de Conférences et Professeur à la Faculté des Sciences Economiques et de Gestion de Tunis et à l’Ecole Supérieure de la Statistique et de l’Analyse de l’Information de Tunis.
                    <br />
                    <br />
                    Il a été conseiller du Ministre de l’Enseignement Supérieur, Directeur du bureau des Etudes, de la Planification et de la Programmation, puis coordinateur du Projet d’Appui à la Réforme de l’Enseignement Supérieur et Président du Comité National d’Evaluation de l’Enseignement Supérieur. En 2007, il a été Directeur de la recherche à l’Association Internationale des Universités (UNESCO-Paris) et conseiller de l’ALECSO pour l’enseignement supérieur et auteur de plusieurs études dans le domaine. Entre 2008 et 2010 il est Directeur du Centre de Formation Internationale et de Recherche en Santé de la Reproduction et Population (ONFP-Ministère de la Santé) Depuis février 2012 il est conseiller du Ministère de l’Education de la République Namibienne responsable de la réforme du financement de l’enseignement supérieur.
                    <br />
                    <br />
                    Ancien Membre du Conseil de la Concurrence, il a aussi été membre du Conseil Supérieur de la Santé, membre fondateur et Vice-Président de l'Association Tunisienne des Sciences Politiques et membre fondateur et ancien Secrétaire Général Adjoint de l'Association des Economistes Tunisiens.
                    <br />
                    <br />
                    Il est l’auteur de nombreuses publications en Tunisie et à l’étranger dans les domaines de l’économie, de la statistique et de la science politique.

                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function Team() {
    const [teamMembers, setTeamMembers] = useState([
        {
            name: 'Mohamed El Hedi ZAIEM',
            // position: 'CEO',
            // bio: 'Amir is the founder and CEO of our company. He has extensive experience in the industry and a proven track record of success.',
            image: HediZAIEM,
            pdf: cvzaiem,
            tabTitle: 'CV Hedi ZAIEM'
        },
        {
            name: 'Yaya BODIAN',
            image: YayaBODIAN,
            pdf: cvyaya,
            tabTitle: 'CV Yaya BODIAN'
        },
        {
            name: 'Daniel GOUADAIN',
            image: DanielGOUADAIN,
            pdf: cvdaniel,
            tabTitle: 'CV Daniel GOUADAIN'
        },
    ]);
    const [teamMembers2, setTeamMembers2] = useState([
        {
            name: 'Feres Othman',
            position: 'CTO',
            // bio: 'Amir is the founder and CEO of our company. He has extensive experience in the industry and a proven track record of success.',
            image: feres,
            // pdf: cvzaiem,
            // tabTitle: 'CV Hedi ZAIEM'
        },
        {
            name: 'Wouroud Zemzem',
            image: wouroud,
            position: 'Responsable réussite client'

        },
        {
            name: 'Dhia Fredj',
            image: dhia,
            position: 'Administrateur systéme'
            // pdf: cvyaya,
            // tabTitle: 'CV Yaya BODIAN'
        },

        {
            name: 'Amira Ben Hamida',
            image: benHamida,
            position: 'Art director'

        },

        {
            name: 'Mohamed Nahouchi',
            image: dx,
            position: 'Développeur'

        },
        {
            name: 'Adem Kaboubi',
            image: adem,
            position: 'Développeur'

        },
        {
            name: 'Nesrine Laffet',
            image: nesrine,
            position: 'Développeur'

        },

        {
            name: 'Siwar Ben Nejma',
            image: siwar,
            position: 'Développeur'

        },
        {
            name: 'Amira Grendi',
            image: amira,
            position: 'Développeur'

        },


        {
            name: 'Abrar Hammami',
            image: abrar,
            position: 'Développeur'

        }
    ]);

    const [modalShow, setModalShow] = useState(false);

    const openPDF = (pdf, tabTitle) => {
        let newTab = window.open(pdf, '_blank');
        setTimeout(() => {
            newTab.document.title = tabTitle;
        }, 1000);
    }

    return (
        <>
            <div style={{
                paddingTop: "16vh",
                paddingBottom: "100px",
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                paddingLeft: "14vw",
                paddingRight: "14vw"
            }}>
                <h1>Notre Équipe </h1>
                <div style={{ paddingTop: 50 }}>
                    <div className="image-container">
                        <img className="circular-image no-pointer resizeButton" src={AmirKboubi} alt={AmirKboubi} />
                    </div>
                    <h2>Amir Kboubi</h2>
                    <h3>PDG</h3>

                </div>
                <h2 style={{ paddingTop: 50 }}>Nos Consultants</h2>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 100,
                    paddingTop: 50,
                    flexWrap: "wrap",
                    justifyContent: "center"
                }}>
                    {teamMembers.map((member, index) => (
                        <div className="team-member" key={index}>
                            <div className="image-container">
                                <img className="circular-image resizeButton" src={member.image} alt={member.name} onClick={() => openPDF(member.pdf, member.tabTitle)} />
                            </div>
                            <h2>{member.name}</h2>
                            <h3>{member.position}</h3>
                            {/* <p>{member.bio}</p> */}
                        </div>
                    ))}
                </div>
                <h2 style={{ paddingTop: 50 }}>Notre Équipe </h2>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 100,
                    paddingTop: 50,
                    flexWrap: "wrap",
                    justifyContent: "center"
                }}>
                    {teamMembers2.map((member, index) => (
                        <div className="team-member" key={index}>
                            <div className="image-container">
                                <img className="circular-image resizeButton no-pointer" src={member.image} alt={member.name} />
                            </div>

                            {/* <div className="image-container">
                            <div className="circular-image resizeButton no-pointer" style={{ backgroundImage: `url(${member.image})` }} src={member.image} alt={member.name} />
                        </div> */}

                            <h2>{member.name}</h2>
                            <h3>{member.position}</h3>
                            {/* <p>{member.bio}</p> */}
                        </div>
                    ))}
                </div>
                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
                {/* <GroupPicture /> */}

            </div>
            <Footer />
        </>
    );

}

export default Team;