import React from 'react'
import { useEffect, useState } from 'react';

export default function MyParagraph({ title, description, children, textMargin }) {
    const [isSmall, setIsSmall] = useState(false)
    const [isMedium, setIsMedium] = useState(false)
    const [isLarge, setIsLarge] = useState(false)
    useEffect(() => {

        function handleResize() {
            setIsSmall(window.innerWidth < 540)
            setIsMedium(window.innerWidth < 980)
            setIsLarge(window.innerWidth < 1400)
        }

        setIsSmall(window.innerWidth < 540)
        setIsMedium(window.innerWidth < 980)
        setIsLarge(window.innerWidth < 1400)

        window.addEventListener('resize', handleResize)

    }, [])
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <p style={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                width: isMedium ? "100%" : "100%",
                fontSize: isSmall ? "15px" : isMedium ? "30px" : "25px",
                textAlign: "left",
                color: "#183c70",
                position: "relative",
                lineHeight: 1.5
            }}>{title}

                {children}
            </p>
            <p style={{
                fontSize: isSmall ? "13px" : "15px",
                textAlign: "justify",
                width: isMedium ? "100%" : "100%",
                marginTop: isSmall ? "-15px" : 0,
                // marginTop: textMargin
            }}>
                {description}
            </p>
        </div>
    )
}
