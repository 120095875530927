import React from 'react'
import Footer from '../components/Footer';
import background from "../assets/background.png";
import Saly31 from '../assets/Saly-31.png'
import Contactform from './Contactform';
import { useEffect, useState } from 'react';

function Partenaires() {
    const [isSmall, setIsSmall] = useState(false)
    const [isMedium, setIsMedium] = useState(false)
    const [isLarge, setIsLarge] = useState(false)
    useEffect(() => {

        function handleResize() {
            setIsSmall(window.innerWidth < 540)
            setIsMedium(window.innerWidth < 980)
            setIsLarge(window.innerWidth < 1400)
        }

        setIsSmall(window.innerWidth < 540)
        setIsMedium(window.innerWidth < 980)
        setIsLarge(window.innerWidth < 1400)

        window.addEventListener('resize', handleResize)

    }, [])
    return (
        <div style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            overflow: "hidden",

        }}>
            <div style={{ display: 'flex', flexWrap: "wrap", minWidth: "300px", justifyContent: "center", alignItems: "center", gap: 30 }}>
                <div >
                    <div style={{
                        display: "flex",
                        flexDirection: isMedium ? "row" : "column",
                        width: "35vw",
                        minWidth: "250px"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            minWidth: "200px",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <p style={{
                                fontFamily: "Poppins",
                                fontWeight: "normal",
                                fontSize: isSmall ? "18px" : isMedium ? "30px" : "50px",
                                textAlign: "center",
                                color: "#183c70",
                                marginRight: "100px"
                            }}>Nos Partenaires</p>
                            <p style={{
                                paddingLeft: 100,
                                fontFamily: "Segoe UI",
                                fontWeight: "normal",
                                fontSize: isSmall ? "13px" : isMedium ? "15px" : "30px",
                                textAlign: "left",
                                color: "#2d2c2c"
                            }}>Nous faisons de nos clients une priorité, c’est pourquoi la confiance envers nos clients est aussi naturelle que déterminante.</p>
                        </div>
                        <img src={Saly31} style={{
                            width: "20vw", objectFit: "contain"
                        }}></img>
                    </div>
                </div>
                <div>
                    <div style={{
                        width: "34vw",
                        // minWidth: "444px",
                        height: 700,
                        borderRadius: "32px",
                        background: "transparent",
                        boxShadow: "3px 5px 20px rgba(0, 0, 0, 0.16)",
                        backdropFilter: "blur(50px)",
                        backgroundColor: "rgba(0, 0, 0, 0.08)",
                        background: "linear-gradient(180deg, rgba(0,103,142,0.19931722689075626) 0%, rgba(37,38,96,0.20211834733893552) 100%)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <div style={{ width: "90%", height: 700, display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between"
                            }}>
                                <Contactform width={296} placeholder={"your name"} />
                                <Contactform width={296} placeholder={"exemple@sthing.tt"} />
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between"
                            }}>
                                <Contactform width={296} placeholder={"your name"} />
                                <Contactform width={296} placeholder={"exemple@sthing.tt"} />
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between"
                            }}>
                                <Contactform width={296} placeholder={"your name"} />
                                <Contactform width={296} placeholder={"exemple@sthing.tt"} />
                            </div>
                            <Contactform placeholder={"subject"} />
                            <div style={{
                                // width: "100%",
                                backgroundColor: "white",
                                borderRadius: 15,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center",
                                position: "relative",
                                fontSize: 12,
                                padding: 10
                            }}>
                                <textarea style={{
                                    minHeight: 153,
                                    maxHeight: 250,
                                    outline: 'none',
                                    border: 'none',
                                    fontFamily: 'Quicksand',
                                    fontWeight: 'normal',
                                    fontSize: 14,
                                    color: '#7c7c7c',
                                    opacity: 0.8,
                                    width: "100%",
                                    height: "100%"
                                }} placeholder={"ex...."}></textarea>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <button style={{
                                    width: '296px',
                                    borderRadius: '15px',
                                    background: 'linear-gradient(180deg, rgba(39,37,94,1) 0%, rgba(3,103,143,1) 100%)',
                                    color: 'white',
                                    height: '44px',
                                    border: '0',
                                    fontWeight: 'bold',
                                    fontSize: '15px'
                                }}>envoyer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Partenaires