import './App.css';
import Home from "./components/Home";
import { BrowserRouter as Router, Routes, Route, Link, NavLink } from "react-router-dom";
import Avantages from './components/Avantages';
import Contact from './components/Contact';
import Services from './components/Services';
import Partenaires from './components/Partenaires';
import Offre from './components/Offre';
import logo from "./assets/logo.png"
import { useEffect, useState } from 'react';
import { slide as Menu } from 'react-burger-menu'
import 'bootstrap/dist/css/bootstrap.min.css';
import Team from './components/Team';
import Features from './components/Features';
import Demo from './components/Demo';

function Header() {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 10) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const [isSmall, setIsSmall] = useState(false)
  const [isMedium, setIsMedium] = useState(false)
  const [isLarge, setIsLarge] = useState(false)
  useEffect(() => {

    function handleResize() {
      setIsSmall(window.innerWidth < 540)
      setIsMedium(window.innerWidth < 980)
      setIsLarge(window.innerWidth < 1400)
    }

    setIsSmall(window.innerWidth < 540)
    setIsMedium(window.innerWidth < 980)
    setIsLarge(window.innerWidth < 1400)

    window.addEventListener('resize', handleResize)

  }, [])

  return (
    <>
      {isMedium ?
        <Menu width={'300px'} styles={{ marginTop: "100px" }} menuClassName={"my-class"}>
          <a className="menu-item" href="/">Accueil</a>
          <a className="menu-item" href="/features">Fonctionnalités</a>
          <a className="menu-item" href="/demo">Démonstration</a>
          <a className="menu-item" href="/avantages">Avantages</a>
          {/* <a className="menu-item" href="/team">Équipe</a> */}
          <a className="menu-item" href="/services">Services</a>
          <a className="menu-item" href="/contact">Contact</a>
          <a className="button" href="https://web.abcplagsys.com/login"> Connexion</a>
        </Menu>
        :
        <div style={{
          // backgroundColor: "rgba(255,0,0,0.5)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          height: 150,
          paddingRight: 50,
          paddingLeft: 95,
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }} className={`${isSticky ? 'sticky' : ''}`}>

          <img style={{
            width: "5vw",
            cursor: "pointer",
          }} alt="logo" src={logo} />
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            maxWidth: 900,
            height: 90
          }}>
            <NavLink exact to="/" activeClassName="active" className="mynavbar" onClick={() => window.scrollTo(0, 0)}>Accueil</NavLink>
            <NavLink to="/features" activeClassName="active" className="mynavbar" onClick={() => window.scrollTo(0, 0)}>Fonctionnalités</NavLink>
            <NavLink to="/demo" activeClassName="active" className="mynavbar" onClick={() => window.scrollTo(0, 0)}>Démonstration</NavLink>
            <NavLink to="/avantages" activeClassName="active" className="mynavbar" onClick={() => window.scrollTo(0, 0)}>Avantages</NavLink>
            {/* <NavLink to="/team" activeClassName="active" className="mynavbar" onClick={() => window.scrollTo(0, 0)}>Équipe</NavLink> */}
            <NavLink to="/services" activeClassName="active" className="mynavbar" onClick={() => window.scrollTo(0, 0)}>Services</NavLink>
            <NavLink to="/contact" activeClassName="active" className="mynavbar" onClick={() => window.scrollTo(0, 0)}>Contact</NavLink>
          </div>

          <div style={{
            // backgroundColor: "rgba(255,0,0,0.5)",
            padding: 20,
            width: 150,
            borderRadius: 40,
            textAlign: "center",
            fontWeight: "bold",
            color: "white",
            filter: "drop-shadow(2px 26px 14px rgba(255, 255, 255, 0.20))",
            // background: "rgb(0, 103, 142)",
            background: "#adcf49",
            cursor: "pointer"
          }} className="resizeButton" onClick={() => window.location.href = 'https://web.abcplagsys.com/login'}>
            Connexion
          </div>

        </div>
      }

    </>


  )
}

function App() {
  return (
    <div className="App">

      <Router>
        <Header />
        <Routes>

          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/partenaires" element={<Partenaires />} />
          <Route path="/offre" element={<Offre />} />
          <Route path="/team" element={<Team />} />
          <Route path="/features" element={<Features />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/avantages" element={<Avantages />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
