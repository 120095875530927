import React from 'react'
import Map, { FullscreenControl, GeolocateControl, Marker, NavigationControl } from 'react-map-gl';
import MapGL from 'react-map-gl';
import map from "../assets/map.png"
import facebook from '../assets/Group 45.png'
import twitter from '../assets/Group 48.png'
import linkedin from '../assets/Group 47.png'
import pinterest from '../assets/Group 46.png'
import { useEffect, useState } from 'react';
import "mapbox-gl/dist/mapbox-gl.css";
import ReactMapGL from "react-map-gl";



function Footer() {
    const [isSmall, setIsSmall] = useState(false)
    const [isMedium, setIsMedium] = useState(false)
    const [isLarge, setIsLarge] = useState(false)

    // const MapWrapper = () => {
    //     const [viewport, setViewport] = useState({
    //         latitude: 37.19441383913846,
    //         longitude: 10.172869081918112,
    //         zoom: 8
    //     });
    //     return (
    //         <MapGL
    //             {...viewport}
    //             mapboxApiAccessToken={"pk.eyJ1IjoidGVjaG5peCIsImEiOiJjbGRidGtrdWIwM3p6M3ZsajRoNWh5eXc3In0.EI7FpaY3p-Bf49jK1B2MSQ"}
    //             mapStyle={"mapbox://styles/mapbox/streets-v11"}
    //             onViewportChange={setViewport}
    //         />
    //     )
    // }
    const [lng, setLng] = useState(10.172869081918112);
    const [lat, setLat] = useState(37.19441383913846);

    useEffect(() => {

        function handleResize() {
            setIsSmall(window.innerWidth < 540)
            setIsMedium(window.innerWidth < 980)
            setIsLarge(window.innerWidth < 1400)
        }

        setIsSmall(window.innerWidth < 540)
        setIsMedium(window.innerWidth < 980)
        setIsLarge(window.innerWidth < 1400)

        window.addEventListener('resize', handleResize)

    }, [])

    return (
        < div className='nn'>

            <div style={{
                display: "flex",
                justifyContent: "space-around",
                padding: 30,
                flexWrap: "wrap"
            }}>

                <div>
                    {/* <img src={map} style={{
                    width: isMedium ? "10vw" : "20vw",
                    minWidth: "100px"
                }}></img> */}
                    {/* <Map
                    initialViewState={{
                        longitude: -100,
                        latitude: 40,
                        zoom: 3.5
                    }}
                    style={{ width: 600, height: 400 }}
                    mapStyle="mapbox://styles/mapbox/streets-v9"
                /> */}
                    {/* <MapWrapper /> */}
                    {/* <Map
                        mapboxAccessToken='pk.eyJ1IjoidGVjaG5peCIsImEiOiJjbGRidGtrdWIwM3p6M3ZsajRoNWh5eXc3In0.EI7FpaY3p-Bf49jK1B2MSQ'
                        style={{
                            width: isMedium ? "200px" : "250px",
                            height: isMedium ? "200px" : "250px",
                            borderRadius: "15px",
                            marginBottom: "50px"
                        }}
                        initialViewState={{
                            longitude: lng,
                            latitude: lat,
                            zoom: isMedium ? 9 : 13
                        }}
                        mapStyle="mapbox://styles/mapbox/streets-v9"
                    >
                        <Marker
                            longitude={lng}
                            latitude={lat}
                        />
                        <NavigationControl
                            position='bottom-right'
                        />
                        <FullscreenControl />
                     
                    </Map> */}
                    <button style={{
                        width: isSmall ? "85px" : "182px",
                        height: isSmall ? "30px" : "58px",
                        borderRadius: "35.5px 35.5px 35.5px 9.91px",
                        background: "rgba(255, 145, 0, 0.2)",
                        fontFamily: "DM Sans",
                        fontWeight: 500,
                        fontSize: isSmall ? "10px" : "20px",
                        color: "#ff9100",
                        border: "none"
                    }}
                        onClick={() => window.location.href = '/contact'}>
                        Contactez nous
                    </button>
                    <p style={{
                        fontFamily: "DM Sans",
                        fontWeight: "normal",
                        fontSize: isSmall ? "20px" : " 64px",
                        letterSpacing: "-0.04em",
                        lineHeight: " 85px",
                        textAlign: "left",
                        color: "#0b0d39"
                    }}> AbcPlagSys.com
                    </p>
                    <div style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        gap: isSmall ? 10 : isMedium ? 50 : 200
                    }}>
                        <p style={{
                            fontFamily: "DM Sans",
                            fontWeight: "normal",
                            fontSize: " 20px",
                            lineHeight: " 35px",
                            textAlign: "left",
                            color: "  #0b0d39",
                            opacity: 0.6
                        }}> Social Media
                        </p>
                        <div style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            flexDirection: "row",
                            gap: 20
                        }}>
                            <img style={{ cursor: "pointer", width: "30px" }} onClick={() => window.open('https://www.facebook.com/TechnixInfo', '_blank')} src={facebook}></img>
                            <img style={{ cursor: "pointer", width: "30px" }} onClick={() => window.open('https://www.facebook.com/TechnixInfo', '_blank')} src={twitter}></img>
                            <img style={{ cursor: "pointer", width: "30px" }} onClick={() => window.open('https://www.facebook.com/TechnixInfo', '_blank')} src={linkedin}></img>

                        </div>


                    </div>
                </div>

                <div style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: isSmall ? 10 : isMedium ? 50 : 200,
                    width: isSmall ? "109vw" : ""
                }}>
                    <div >

                        <p style={{
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            fontSize: isSmall ? "10px" : isMedium ? "15px" : "18px",
                            lineHHeight: "32px",
                            textAlign: "left",
                            color: "#000",
                        }}>
                            Support
                        </p>

                        <div style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            flexDirection: "row",
                            gap: 20

                        }}>
                            <p style={{
                                fontFamily: "DM Sans",
                                fontWeight: 500,
                                fontSize: isMedium ? "15px" : "16px",
                                lineHHeight: "20px",
                                textAlign: "left",
                                color: "#0B0D39"

                            }}>
                                <p style={{ cursor: "pointer" }} onClick={() => window.location.href = '/Home'}>Accuile</p>
                                {/* <br /> */}
                                <p style={{ cursor: "pointer" }} onClick={() => window.location.href = '/features'}>Fonctionnalités</p>
                                {/* <br /> */}
                                <p style={{ cursor: "pointer" }} onClick={() => window.location.href = '/contact'}>Contact</p>
                                {/* <br /> */}


                            </p>
                            <p style={{
                                fontFamily: "DM Sans",
                                fontWeight: 500,
                                fontSize: isSmall ? "10px" : isMedium ? "15px" : "16px",
                                lineHHeight: "20px",
                                textAlign: "left",
                                color: "#0B0D39"

                            }}>
                                <p style={{ cursor: "pointer" }} onClick={() => window.location.href = '/Avantages'}>Avantages</p>
                                {/* <br /> */}
                                <p style={{ cursor: "pointer" }} onClick={() => window.location.href = '/Services'}>Services</p>
                                {/* <br /> */}
                                {/* <p style={{ cursor: "pointer" }} onClick={() => window.location.href = '/Team'}>Équipe</p> */}
                            </p>
                        </div>
                    </div>
                    <div>
                        <p style={{
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            fontSize: isSmall ? "10px" : isMedium ? "15px" : "18px",
                            lineHHeight: "32px",
                            textAlign: "left",
                            color: "#0B0D39",
                        }}>
                            Contact Us
                        </p>
                        <p style={{
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: isSmall ? "10px" : isMedium ? "15px" : "16px",
                            lineHHeight: "20px",
                            textAlign: "left",
                            color: "#0B0D39",
                        }}>
                            <p>+213550806744</p>
                            {/* <br /> */}
                            <p>contact@abcplagsys.com</p>
                            {/* <br /> */}
                            <p>BP 65C 23008 Algeria</p>
                        </p>
                        <div style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            flexDirection: "row",
                            gap: 20
                        }}>
                            {/* <img style={{ cursor: "pointer", width: "30px" }} onClick={() => window.open('https://www.facebook.com/TechnixInfo', '_blank')} src={facebook}></img> */}
                            {/* <img src={twitter}></img>
                        <img src={linkedin}></img>
                        <img src={pinterest}></img> */}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Footer